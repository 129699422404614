// extracted by mini-css-extract-plugin
export var card = "card-module--card--TTLUX";
export var cardBGdark = "card-module--cardBGdark--8iGQY";
export var cardBGlight = "card-module--cardBGlight--acvKd";
export var cardWithImage = "card-module--cardWithImage--6h6fF";
export var container = "card-module--container--DmkrU";
export var featuredImage = "card-module--featuredImage--l40Wk";
export var innerWrapper = "card-module--innerWrapper--2wqJU";
export var metaData = "card-module--metaData--Ddda-";
export var dateSeparator = "card-module--dateSeparator--FeXxr";
export var shape = "card-module--shape--+rj24";
export var bottom = "card-module--bottom--PZkf8";
export var link = "card-module--link--NbTGu";