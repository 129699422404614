import React from 'react';
import Card, { CardProps } from 'components/card';
import Column, { ColumnResponsiveSizes } from 'components/column';
import { useEmblaCarousel } from 'embla-carousel/react';
import clsx from 'clsx';

import * as styles from './card-carousel.module.scss';

export type OnPageChangeArgs = {
  currentPage: number;
  totalPages: number;
  canScrollNext?: boolean;
  canScrollPrev?: boolean;
};

export type CardCarouselProps = {
  data: CardProps[];
  perPage?: 3 | 4;
  scrollCount: number;
  backgroundColor?: string;
  loop?: boolean;
  className?: string;
  progressClassName?: string;
  onPageChange?: (args: OnPageChangeArgs) => void;
};

export const newsToCards = (news: GatsbyTypes.DatoCmsNews[]): CardProps[] =>
  news.map((post, index) => ({
    id: post.id,
    background: index % 2 === 0 ? 'dark' : 'light',
    featuredImage: post.featuredImage,
    title: post.title || '',
    metaText: {
      text: post.category?.title || '',
      date: post.meta?.publishedAt,
    },
    link: {
      id: post.id,
      slug: post.slug,
      model: {
        apiKey: post.model?.apiKey || '',
      },
    },
  }));

export type CardCarouselHandle = {
  scrollPrev: () => void;
  scrollNext: () => void;
};

const CardCarousel = React.forwardRef<CardCarouselHandle, CardCarouselProps>(
  (
    {
      perPage = 4,
      scrollCount,
      backgroundColor = 'mint',
      className,
      onPageChange,
      loop = false,
      progressClassName,
      ...props
    },
    forwardedRef
  ) => {
    const [viewportRef, embla] = useEmblaCarousel({
      skipSnaps: true,
      dragFree: false,
      loop,
      speed: 10,
      inViewThreshold: 100,
      slidesToScroll: scrollCount,
      align: 'start',
      containScroll: 'keepSnaps',
    });

    const scrollNext = React.useCallback(() => {
      if (embla?.canScrollNext()) {
        embla?.scrollNext();
      }
    }, [embla]);
    const scrollPrev = React.useCallback(() => {
      if (embla?.canScrollPrev()) {
        embla?.scrollPrev();
      }
    }, [embla]);

    React.useImperativeHandle(forwardedRef, () => ({
      scrollNext: () => {
        scrollNext();
      },
      scrollPrev: () => {
        scrollPrev();
      },
    }));

    const onChangeHandle = React.useCallback(() => {
      if (!embla || !onPageChange) return;

      onPageChange({
        currentPage: embla.dangerouslyGetEngine().index.get(),
        totalPages: Math.ceil(props.data.length / scrollCount),
        canScrollNext: embla.canScrollNext(),
        canScrollPrev: embla.canScrollPrev(),
      });
    }, [embla, onPageChange, props.data.length, scrollCount]);

    React.useEffect(() => {
      if (!embla) return;

      embla.on('scroll', onChangeHandle);
      onChangeHandle();

      return () => embla.destroy();
    }, [embla, onChangeHandle]);

    let cardColumnConfig: ColumnResponsiveSizes = {
      xl: {
        width: 3,
        offset: 0,
      },
      lg: {
        width: 3,
        offset: 0,
      },
      md: {
        width: 4,
        offset: 0,
      },
      sm: {
        width: 4,
        offset: 0,
      },
    };

    switch (perPage) {
      case 3:
        cardColumnConfig = {
          xl: {
            width: 4,
            offset: 0,
          },
          lg: {
            width: 4,
            offset: 0,
          },
          md: {
            width: 6,
            offset: 0,
          },
          sm: {
            width: 6,
            offset: 0,
          },
        };
    }

    return (
      <>
        <div
          className={clsx(
            className,
            (styles as never)[`sliderPerPage${perPage}`],
            (styles as never)[`slideCount${props.data.length}`],
            styles.sliderViewport,
            (styles as never)[`sliderBG${backgroundColor}`]
          )}
          ref={viewportRef}
        >
          <Column className={styles.sliderContainer}>
            {props.data.map((post, index) => (
              <Column key={post.id} className={styles.sliderSlide} {...cardColumnConfig}>
                <div className={styles.sliderSlideInner}>
                  <Card {...post} />
                </div>
              </Column>
            ))}
          </Column>
        </div>
      </>
    );
  }
);

CardCarousel.displayName = 'CardCarousel';

export default CardCarousel;
