import Link, { LinkType } from 'components/link';
import React from 'react';
import clsx from 'clsx';
import Image, { ImageProps } from 'components/image';
import MatrixFigure from 'components/matrix-figure';

import * as styles from './card.module.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export type CardProps = {
  id?: string;
  background?: 'light' | 'dark';
  title: string;
  className?: string;
  featuredImage?: ImageProps;
  metaText?: {
    text: string;
    date?: string;
  };
  linkText?: string;
  link?: LinkType;
};

const Card: React.FC<CardProps> = ({
  background = 'dark',
  metaText,
  title,
  featuredImage,
  link,
  linkText = 'Läs mer',
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={clsx(
        styles.card,
        featuredImage && styles.cardWithImage,
        (styles as never)[`cardBG${featuredImage ? 'dark' : background}`]
      )}
      link={link}
    >
      <div className={styles.innerWrapper}>
        <div className={styles.container}>
          {metaText && (
            <label className={styles.metaData}>
              {metaText.text}
              {metaText.date && (
                <>
                  <span className={styles.dateSeparator}>|</span>
                  {metaText.date}
                </>
              )}
            </label>
          )}
          {!featuredImage && (
            <MatrixFigure
              fig={[
                [' ', 'x'],
                ['x', ' '],
              ]}
              className={styles.shape}
            />
          )}
          {featuredImage && <Image className={styles.featuredImage} {...featuredImage} />}
          <div className={styles.bottom}>
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
            {link && (
              <Link className={styles.link} arrow={'right'} arrowFill>
                <h3>{t(linkText)}</h3>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default React.memo(Card);
