// extracted by mini-css-extract-plugin
export var sliderViewport = "card-carousel-module--sliderViewport--IKx27";
export var sliderProgress = "card-carousel-module--sliderProgress--SuMMY";
export var sliderPerPage4 = "card-carousel-module--sliderPerPage4--Zn10F";
export var slideCount1 = "card-carousel-module--slideCount1--1aM2i";
export var sliderContainer = "card-carousel-module--sliderContainer--HlD51";
export var slideCount2 = "card-carousel-module--slideCount2---caqk";
export var slideCount3 = "card-carousel-module--slideCount3--WaSIR";
export var slideCount4 = "card-carousel-module--slideCount4--onJBS";
export var sliderPerPage3 = "card-carousel-module--sliderPerPage3--blMyD";
export var sliderSlide = "card-carousel-module--sliderSlide--ezy84";
export var sliderSlideInner = "card-carousel-module--sliderSlideInner--FNrse";
export var sliderPagination = "card-carousel-module--sliderPagination--Hpzsz";
export var sliderBGlight = "card-carousel-module--sliderBGlight--WsDxL";
export var sliderBGmint = "card-carousel-module--sliderBGmint--XWjsl";
export var sliderBGblue = "card-carousel-module--sliderBGblue--11h3H";
export var sliderBGneon = "card-carousel-module--sliderBGneon--aZs-E";
export var sliderBGconcrete = "card-carousel-module--sliderBGconcrete--oHpem";